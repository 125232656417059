body,
html {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* Not supported in IE 6 & IE 7 */
}

/* filhal band kia hai */
/* width: 400px; */
.mycard:hover {
    /* width: 336px; */
    width: 360px;
    height: 191px;
    background: #32A9E0;
    color: #FFFFFF;
    border-radius: 20px;
    position: relative;
    text-transform: capitalize;
    box-sizing: border-box;
    margin: 4px 2px 4px 2px;
    cursor: pointer;
}


.mycard {
    /* width: 400px; */
    /* width: 336px; */
    width: 360px;
    height: 191px;
    background: #FFFFFF;
    color: #1E1E1E;
    border: 1px solid #979797;
    border-radius: 20px;
    text-transform: capitalize;
    position: relative;
    margin: 4px 2px 4px 2px;
}

.one {
    position: absolute;
    /* font-size: 57px; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    /* color: #BDEAFF; */
    font-size: 79px;
    top: -20px;
    color: #BDEAFF;
    line-height: 118px;
}

/* .two{
    position: absolute;
    top: 56px;
    left: 26%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 75.2692px;
    line-height: 46px;
} */
.two {
    position: absolute;
    top: 74px;
    left: 17%;
    font-family: 'Poppins';
    /* color: #FFFFFF; */
    font-style: normal;
    font-weight: 900;
    font-size: 57.2692px;
    line-height: 46px;
    /* text-transform: uppercase; */
}

.three {
    position: absolute;
    top: 68%;
    left: 105px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 35.2692px;
    line-height: 46px;
    /* color: #FFFFFF; */
}

.two.vip {
    left: 111px;
    top: 70px;
    font-size: 60px;
    text-transform: uppercase;
}

/* dropoff start */

.two.book {
    left: 41%;
    top: 42px;
    /* width: 208px; */
    /* height: 106px; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 36px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 36px;
    width: 208px;
    height: 108px;
}

.one.book {
    position: absolute;
    /* font-size: 57px; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    /* color: #BDEAFF; */
    font-size: 79px;
    top: 3%;
    left: 0%;
    color: #BDEAFF;
    line-height: 118px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;

    font-size: 124px;
    line-height: 186px;
    width: 193px;
    height: 186px;

}

.one.lax {
    position: absolute;
    /* font-size: 57px; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    /* color: #BDEAFF; */
    /* font-size: 79px; */
    top: 1%;
    left: 1%;
    color: #BDEAFF;
    line-height: 118px;
    font-family: 'Poppins';
    font-style: normal;

    font-weight: 500;
    font-size: 124px;
    line-height: 186px;
    width: 193px;
    height: 186px;
}

.two.lax {
    left: 47%;
    top: 37px;
    /* width: 208px; */
    /* height: 106px; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 36px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 34px;
    width: 208px;
    height: 108px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 36px;
}


.one.terminal {
    position: absolute;
    /* font-size: 57px; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    /* color: #BDEAFF; */
    /* font-size: 79px; */
    top: 3%;
    left: 2px;
    color: #BDEAFF;
    /* line-height: 118px; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    /* font-weight: 500; */
    font-size: 124px;
    line-height: 186px;
    width: 193px;
    height: 186px;
    /* left: 120px; */
    /* top: 2313.69px; */
}




.two.terminal {
    left: 43%;
    top: 2px;
    /* width: 208px; */
    /* height: 106px; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    /* font-size: 24px;
    line-height: 36px; */
    font-size: 24px;
    line-height: 36px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    /* width: 208px; */
    height: 108px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    /* font-size: 23px;
    line-height: 37px; */
    width: 212px;
    /* width: 209px; */
    height: 190px;
}

.two.vehicle {

    left: 38%;
    top: 24px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    width: 221px;
    height: 144px;

}

.two.confirmname {

    left: 47%;
    top: 42px;
    width: 188px;
    height: 116px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;

}

.two.terminalcurbside {
    width: 194px;
    height: 191px;

    left: 47%;
    top: 20px;


    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;

}




/* dropoff end */



.two.additional {
    font-size: 46px;
    left: 30px;
}


.Reactangle {
    position: relative;
    width: 1440px;
    height: 6730px;

    background: #FFFFFF;
}

.Rectangle1414 {
    position: absolute !important;
    width: 910px;
    height: 109px;
    /* left: 214px; */
    /* left: 175px; */
    /* left: 274px; */
    /* left: 182px; */
    /* left: 345px; */
    top: 470px;
    z-index: 1;
    background: white !important;
    border-radius: 89px;
    /* border: 1px solid black; */
    /* box-shadow: 0 4px 2px -2px #222222;; */


    display: flex;
    justify-content: center;
}




.Reactangle1 {
    position: absolute;
    width: 1386px;
    height: 59px;
    left: 27px;
    top: 26px;

    background: #32A9E0;
    border-radius: 30px 30px 0px 0px;
}

.registerbtn {
    position: absolute;
    width: 151px;
    height: 31px;
    left: 1022px;
    top: 17px;

    background: #FFFFFF;
    border-radius: 219px;
}

.registerbtntext {
    color: #32A9E0;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 104%;

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
}

/* .registerbtntext{
    position: absolute;
width: 151px;
height: 20px;
left: 1022px;
top: 45px;

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 104%;
/* or 17px */

/* display: flex;
align-items: center;
text-align: center; */
/* text-transform: capitalize;

color: #32A9E0;
} */
*/ .signinbtn {
    position: absolute;
    width: 151px;
    height: 31px;
    left: 1189px;
    top: 40px;

    background: #FFFFFF;
    border-radius: 219px;

}

/* start */
/* after sider */
/* before meeting */
/* .Rectangle1414 {
    position: absolute !important;
    width: 1000px;
    height: 109px; */
/* left: 160px;
    top: 639px; */
/* left: 214px; */
/* Yeh abhi yak kia hai */
/* left: 175px; */
/* left: 274px; */
/*  */
/* left: 184px; */

/* top: 710px; last wala comment */

/* top: 704px;
    z-index: 1;
    background: #FFFFFF !important;
    ;
    border-radius: 89px; */
/* box-shadow: 2px 10px rgba(34, 34, 34, 0.6); */
/* } */

/* after meeting */
/* .Rectangle1414 {
    position: absolute !important;
    width: 1000px;
    height: 109px;
    /* left: 214px; */
/* left: 175px; */
/* left: 274px; */
/* left: 182px; */
/* left: 345px; */
/* top: 448px;
    z-index: 1;
    background: #FFFFFF !important;
    border-radius: 89px; */
/* box-shadow: 2px 10px rgb(34 34 34 / 60%); */
/* }  */

/* end */

/* .reactangleoutside {
    background: red
} */
/* .reactangleoutside {
    
    position: absolute;
    
    left: 0px;
    width: 100%;
    height: 132px;
    box-shadow: 0px 32px 60px rgb(0 0 0 / 12%);
    top: 689px;
} */
/* .reactangleoutside {
    position: absolute;
    left: 0px;
    width: 100%;
    height: 132px;
    box-shadow: 0px 32px 60px rgb(0 0 0 / 12%);
    top: 620px;
} */
/* after meeting */
/* .reactangleoutside {
    position: absolute;
    left: 0px;
    width: 100%;
    height: 132px;
    box-shadow: 0px 32px 124px rgb(0 0 0 / 58%);
    top: 381px;
} */
/* last new change */
.reactangleoutside {
    position: absolute;
    /* width: 100px; */
    left: 0px;
    width: 100%;
    height: 22px;
    /* box-shadow: 0px 32px 60px rgb(0 0 0 / 12%); */
    /* background: red; */
    box-shadow: 0px 32px 124px rgb(0 0 0 / 58%);
    top: 489px;
}

.wemeeth1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 80px;
    line-height: 78px;
    color: #5F5F5F;
    align-items: center;
    text-transform: capitaliz
}

.wemeetp {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    text-transform: none;
    color: #5F5F5F;
}

.servicesh1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 144.5%;
    color: #5F5F5F;
}

.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0rem;
}

/* .hereitwork {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 80px;
    line-height: 121.15%;
    color: #5F5F5F;
} */
/* .hereitwork {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 78px;
    line-height: 121.15%;
    margin-top: 1%;
    position: absolute;
    color: #5F5F5F;
} */
.hereitwork {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 80px;
    line-height: 96.92px;
    /* margin-top: 5%; */
    /* letter-spacing: 0.3rem; */
    position: absolute;
    color: #5F5F5F;
    /* margin-left: 8%; */
}

.booknow {
    /* position: absolute;
    width: 187px;
    height: 87px;
    left: 1140px;
    top: 590px; */

    /* width: 187px;
    height: 87px; */


    width: 130px;
    height: 41px;
    background: #32A9E0;
    border-radius: 219px;
}

.booknowbtn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    padding-top: 6px;
    line-height: 104%;
    color: #FFFFFF;
}

.booknowbtn {


    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 104%;


    color: #FFFFFF;
}

.booknowbtn2 {
    width: 250px;
    color: #000;
    font-size: 400px;
    border-radius: 219px;
    background: #FFFFFF;
    box-shadow: "0px 14px 50px rgba(0, 0, 0, 0.15);";

}

.contactless {
    width: 388px;
    height: 43px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 144%;
    /* or 37px */

    display: flex;
    align-items: center;
    letter-spacing: 0.15em;
    text-transform: uppercase;

    color: #4A4A4A;
}

.meetyou {
    width: 655px;
    height: 296px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 80px;
    line-height: 107px;
    display: flex;
    align-items: center;
    text-transform: capitalize;

    color: #5F5F5F;

}

.bookpara {
    width: 633px;
    height: 195px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

    line-height: 34px;
    /* or 189% */

    text-transform: capitalize;

    color: #5F5F5F;
}

.serviclog {
    background: #1F1D1D;
    border-radius: 30px;
    /* box-shadow: 0px 15px 14px */
}

.rateheading {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 60px;
    line-height: 80.49px;

}

.rateprice {
    font-family: "Inter";
    font-weight: 600;
    font-size: 50px;
    line-height: 39.1px;

}

.day {
    font-family: "Inter";
    font-weight: 600;
    font-size: 30px;
    line-height: 39.1px;

}

.ratedescription {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 26.1px;
    letter-spacing: 0.1rem;
    text-transform: none;

}

.servicename {
    font-family: "Sora" !important;
    font-weight: 600;
    font-size: 24px;
    line-height: 33.1px;
    letter-spacing: 0.1rem;
    text-transform: capitalize;

}

.pricingsection {
    background: #BDEAFF;
    /* box-shadow: 0px 32px 60px rgba(0, 0, 0, 0.12); */
    border-radius: 100px;
}

.ftco-section2 {
    padding: 3em 0;
    position: relative;
}

hr.hrclass {
    border: 1px solid #1E1E1E;
    margin: 0px 80px 0px 80px;
    height: 0px;
}

.star5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 106.65%;
    color: #5F5F5F;
    
}

.outline-dark:hover {
    color: black;
}

.bs-example {
    /* margin: 20px; */
    /* padding: 3em; */
    /* padding: 0px 2px 0px 2px; */
}

.bluediv {
    background: #32A9E0;
    /* border-radius: 30px 30px 0px 0px; */
}

.star5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 80px;
    line-height: 85.32px;
color: #5F5F5F;

}

.bookbtn {
    /* style={{background: "#32A9E0", width: "200px", color: "#FFFFFF", fontWeight: "400px", borderRadius: "219px",  boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }}
     */
    background: #32A9E0;
    width: 200px;
    color: "white";
    font-weight: 400;
    border-radius: 219px;
    /* box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.15); */

    height: 78px;
    /* new ad */
    margin-top: -13px;
}

.abc {
    margin-left: 35px;
}


.spanoftitle {
    margin-left: 38px;
}

.mt-10 {
    margin-top: 7rem !important;
}

.mt-15 {
    margin-top: 10rem !important;
}

.textcenter {
    text-align: center;
}

/* .o1{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 124px;
line-height: 186px;
color: #BDEAFF;
} */

.o1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 114px;
    color: #BDEAFF;
}

.card.mb-4.safdar:hover {
    /* background-color: #32A9E0; */
    background: #32A9E0;
    color: #FFFFFF;
    /* width: 336px; */
    width: 360px;
    float: left;
    margin-top: 40px;
    height: 191px;
    /* border-radius: 5px; */
    border-radius: 20px;
    box-sizing: border-box;
    /* padding: 13px 25px 25px 25px; */
    padding: 13px 7px 25px 7px;
    text-align: center;
    position: relative;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    cursor: pointer;
    /* font-size: 34px;
    line-height: 30px; */


    /* font-size: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    line-height: 40px; */
}


/* .card.mb-4.safdar {

    background: #FFFFFF;
    color: #1E1E1E;

    width: 336px;
    /* min-width: 350px; */
/* float: left;
    margin-top: 40px;
    height: 191px; */
/* border-radius: 5px; */
/* border-radius: 20px;
    box-sizing: border-box;
    padding: 13px 25px 25px 25px;
    text-align: center;
    position: relative;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%); */
/* font-size: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    line-height: 40px; */

/* } */


.vipserviceh1:hover {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    /* font-size: 60px;
    line-height: 35px; */
    font-size: 34px;
    line-height: 30px;
    color: #FFFFFF;
}

.vipserviceh1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    /* font-size: 60px;
    line-height: 35px; */
    font-size: 34px;
    line-height: 30px;
    color: #1E1E1E;
}

.vipserviceh5:hover {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    color: #FFFFFF;

}

.vipserviceh5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    color: #1E1E1E;
}

.dropoffp {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    /* color: red; */
    text-transform: capitalize;
    line-height: 36px;
}

.pickupp {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    /* color: red; */
    text-transform: capitalize;
    line-height: 36px;
}


.card.mb-4.safdar {
    background: #FFFFFF;
    color: #1E1E1E;
    /* width: 336px; */
    min-width: 360px;
    float: left;
    margin-top: 40px;
    height: 191px;
    /* border-radius: 5px; */
    border-radius: 20px;
    box-sizing: border-box;
    padding: 13px 7px 25px 7px;
    text-align: center;
    position: relative;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    /* font-size: 34px;
    line-height: 30px; */

    /* font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px; */
}

a.footerli {
    color: #fff;
}

.servicebigcard {
    display: flex;
}

.b-title {
    color: #5F5F5F;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    font-size: 20px;
}

.b-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 110%;
    color: #B6B6B6;
}

.testinomialh {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 26px;
    line-height: 17.16px;
    color: #5F5F5F;
    letter-spacing: 0.3rem;
    text-transform: capitalize;
}

.xtreme {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 14px;
    line-height: 30.1px;
    color: #32A9E0;
    letter-spacing: 0.3rem;
    text-transform: capitalize;

}

@media screen and (min-width:1040) and (max-width: 1440px) {
   

    .Rectangle1414 {
        position: absolute;
        width: 900px;
        height: 109px;
        /* left: 214px; */
        left: 271px;
        top: 704px;
        z-index: 1;
        background: #FFFFFF;
        border-radius: 89px;
        /* box-shadow: 2px 10px rgb(34 34 34 / 60%); */
    }


} .reservation2 {
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 26px !important;
        line-height: 30px !important;
        color: #4A4A4A !important;
        margin-top: 16%;
    

  }

@media only screen and (max-width: 2560px)  and  (min-width: 2000px) {
    .wemeetp {
        font-family: 'Inter' !important;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 55px;
        text-transform: none;
        color: #5F5F5F;
    }
    .reservation2 {
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 26px !important;
        line-height: 30px !important;
        color: #4A4A4A !important;
        margin-top: 10%;
    

  }
  .reservation22{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 26px;
line-height: 17.16px;
letter-spacing: 0.3rem;
/* or 38px */
color: #5F5F5F;
margin-top: 8%;
}




}

 @media screen  and (max-width: 1440px) {
    .reservation2 {
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 26px !important;
        line-height: 30px !important;
        color: #4A4A4A !important;
        margin-top: 16%;
    

  }
 }

  

@media screen and (max-width:1024px) {
    .reservation2 {
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 26px !important;
        line-height: 30px !important;
        color: #4A4A4A !important;
        margin-top: 23%;
    }

    .reservation22{
        font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 17.16px;
    letter-spacing: 0.3rem;
    /* or 38px */
    color: #5F5F5F;
    margin-top: 8%;
    }
    .one.terminal {
        position: absolute;
        /* font-size: 57px; */
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        /* color: #BDEAFF; */
        /* font-size: 79px; */
        top: 3%;
        left: 0px;
        color: #BDEAFF;
        /* line-height: 118px; */
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        /* font-weight: 500; */
        font-size: 92px;
        line-height: 186px;
        width: 193px;
        height: 186px;
        /* left: 120px; */
        /* top: 2313.69px; */
    }

    .two.terminal {
        left: 36%;
        top: 2px;
        /* width: 208px; */
        /* height: 106px; */
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        /* width: 208px; */
        height: 108px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        width: 212px;
        /* width: 209px; */
        height: 190px;
    }

    .two.book {
        left: 41%;
        top: 42px;
        /* width: 208px; */
        /* height: 106px; */
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        line-height: 36px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        line-height: 36px;
        width: 208px;
        height: 108px;
    }


    .two.terminalcurbside {
        width: 194px;
        height: 191px;
        left: 47%;
        top: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
    }

    .two.confirmname {
        left: 47%;
        top: 42px;
        width: 188px;
        height: 116px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
    }

    .two.vehicle {
        left: 38%;
        top: 24px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        width: 208px;
        height: 144px;
    }

    .two.lax {
        left: 42%;
        top: 37px;
        /* width: 208px; */
        /* height: 106px; */
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        line-height: 36px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 34px;
        width: 208px;
        height: 108px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 36px;
    }

    .one.lax {
        position: absolute;
        /* font-size: 57px; */
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        /* color: #BDEAFF; */
        /* font-size: 79px; */
        top: 1%;
        left: 0%;
        color: #BDEAFF;
        line-height: 118px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 92px;
        line-height: 186px;
        width: 193px;
        height: 186px;
    }

    .one.book {
        position: absolute;
        /* font-size: 57px; */
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        /* color: #BDEAFF; */
        font-size: 79px;
        top: 3%;
        left: 0%;
        color: #BDEAFF;
        line-height: 118px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 92px;
        line-height: 186px;
        width: 193px;
        height: 186px;
    }


}


/* @media screen and (min-width: 768px) and (max-width: 1023px) { */
/* .Rectangle1414 {
        left: 70px;
        position: absolute !important;
        height: 109px;
        background: #FFFFFF !important;
        top: 759px;
    } */
/* .Rectangle1414 {
        left: 14px;
        position: absolute !important;
        height: 109px;
        background: #FFFFFF !important;
        top: 514px;
    } */

/* .Rectangle1414 {
        left: 14px;
        position: absolute !important;
        height: 109px;
        background: #FFFFFF !important;
        top: 464px;
    }

    .abc {
        margin-left: 40px;
    }
} */



@media screen and (max-width: 1180px) {
   

.carimg{
    width: 100% !important;
}
}

@media screen and (max-width: 768px) {

    .reservation2 {
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 26px !important;
        line-height: 30px !important;
        color: #4A4A4A !important;
        margin-top: 30%;
    }

    .reservation22{
        font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 17.16px;
    letter-spacing: 0.3rem;
    /* or 38px */
    color: #5F5F5F;
    margin-top: 8%;
    }
    .addresscallmessage{
        font-family: "Poppins" !important;
        font-weight: 300 !important;
        font-size: 11px !important;
        line-height: 30.1px !important;
        letter-spacing: 0.1rem !important;
    }

    .lastbtn {
        width: 220px !important;
        color: rgb(0, 0, 0) !important;
        border-radius: 219px;
        background: rgb(255, 255, 255) !important;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 50px !important;
        font-size: 24px !important;
        line-height: 24.96px !important;
    }
    .Rectangle1414 {
        /* left: 50px; */
        /* before width */
        /* width: 712px; */
        width: 680px;
        /* height: 500px; */
        position: absolute !important;
        background: #FFFFFF !important;
        top: 450px;
    }

    .icoon {
        padding: 0px 17px !important;

    }

    .cdc {
        margin: 0px 8px !important;
    }

    .bookbtn {
        /* style={{background: "#32A9E0", width: "200px", color: "#FFFFFF", fontWeight: "400px", borderRadius: "219px",  boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }}
         */
        background: #32A9E0;
        width: 127px;
        color: "#FFFFFF";
        font-weight: 400;
        border-radius: 219px;
        /* box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.15); */
    }


    .ftco-navbar-light.scrolled .nav-item>.nav-link {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }

    .ftco-navbar-light .navbar-nav>.nav-item>.nav-link {
        padding-left: 1px;
        padding-right: 1px;
        padding-bottom: 6px;
        padding-top: 1px !important;
        color: #fff;
    }

    .spanoftitle {
        margin-left: 0px;
    }

    .wemeetp {
        font-family: "Inter"!important;
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
        text-transform: none;

}

.wemeeth1 {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 34px;
    line-height: 55px;
    align-items: center;
    text-transform: "capitaliz";
}

.wemeeth3{
    font-family: "Poppins";
    font-weight: 500;
    font-size: 20px !important;
    line-height: 34px !important;
    letter-spacing: 0.5rem;
}
}
@media screen and (max-width: 820px) {
    .Rectangle1414 {
        /* left: 50px; */
        /* width: 712px; */
        width: 750px;
        height: 109px;
        position: absolute !important;
        background: #FFFFFF !important;
      
        border-radius: 65px;
        box-shadow: 0px 32px 124px rgb(0 0 0 / 58%);
    }

    .bookbtn{
     
            background: #32A9E0;
            width: 150px;
            color: "white";
            font-weight: 400;
            border-radius: 219px;
            /* box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.15); */
            height: 78px;
            margin-top: -13px;

}
.lastbtn {
    width: 200px !important;
    color: #000;
    border-radius: 219px;
    background: #fff;
    box-shadow: 0 14px 50px rgba(0,0,0,.15);
    font-size: 24px;
    line-height: 24.96px;
}

.wemeeth1 {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 33px;
    line-height: 46px;
    align-items: center;
    text-transform: "capitaliz";
}


.hereitwork {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 66px;
    position: absolute;
    color: #5f5f5f;
}

.star5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 60px;
    color: #5f5f5f;
}

.letgettouch {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 70px;
}

.rateheading {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 50px;
    line-height: 70.49px;
   
}

.rateprice {
    font-size: 40px;
}

.abc {
    margin-left: 50px;
}

.reservation-span, .reservation-span:hover {
    font-size: 15px;
    position: absolute;
    top: 14%;
    left: 26%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    line-height: 18.7px;
 
}

.testimony-wrap.reservation {
    background: #fff;
    color: #000;
    height: 240px;
    border: 1px solid #979797;
}

.carimg{
    width: 100% !important;
}
}

@media screen and (max-width: 565px) {
    .Rectangle1414 {
        /* left: 50px; */
        /* width: 712px; */
        width: 476px;
        height: 327px;
        position: absolute !important;
        background: #FFFFFF !important;
        top: 1029px;
        border-radius: 65px;
        box-shadow: 0px 32px 124px rgb(0 0 0 / 58%);
    }

}

@media screen and (max-width: 475px) {
    .Rectangle1414 {
        left: 21px;
        width: 383px;
        height: auto;
        position: relative !important;
        top: -67px;
        background: #FFFFFF !important;
        border: 2px solid black;
        text-align: center;
        padding-bottom: 14px;
    }

    .spanoftitle {
        margin-left: 0px;
    }

    .mt-10 {
        margin-top: 0px !important;
    }

    .mt-5 {
        margin-top: 0px !important;
    }

}

@media screen and (max-width: 425px) {
    .arrival {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 20px;
        line-height: 20.8px;
        color: #8E8E8E;
        padding: 0px 0px 0px 0px !important;
    }
    .reversecol{
        flex-direction: column-reverse;
    }
    .star5 {
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 35px !important;
        line-height: 80.65% !important;
color: #5F5F5F !important;

    }

    .btnlst{
        padding: 77px;
    }

    .addresscallmessage{
        font-family: "Poppins" !important;
        font-weight: 300 !important;
        font-size: 15px !important;
        line-height: 20px !important;
        letter-spacing: 0.1rem !important;
    }
    .ratedescription {
        font-family: "Inter";
        font-weight: 400;
        font-size: 12px;
        line-height: 26.1px;
        letter-spacing: 0.1rem;
        text-transform: none;
        margin: 10px;

    
    }

    .servicename {
        font-family: "Sora" !important;
        font-weight: 600;
        font-size: 20px;
        line-height: 33.1px;
        letter-spacing: 0.1rem;
        text-transform: capitalize;
        margin: 10px;
    }
   

    .rateprice {
        font-family: "Inter" !important;
        font-weight: 600 !important;
        font-size: 50px !important;
        line-height: 10px !important;
        padding: 35px !important;
    }

    .Rectangle1414 {
        /* left: 21px; */
        left: 5px;
        width: 383px;
        height: auto;
        position: relative !important;
        top: -67px;
        border: 2px solid black;
        text-align: center;
        padding-bottom: 14px;
    }

    .rectanglecol-m-3 {
        margin: 15px 0px 3px 0px;
    }

    .spanoftitle {
        margin-left: 0px;
    }

    .abc {
        margin-left: 0px;
    }


    .clearfix .fss {
        display: flex;
        flex-direction: row;
        padding: 0px 1px 0px 1px;
    }

    .wemeeth1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 40px;
        text-align: center;
        color: #5F5F5F;
        align-items: center;
        text-transform: capitalize;
    }

    .wemeeth3 {
        /* text-align: center  !important; */
        font-family: "Poppins" !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 34.5px !important;
        color: #4A4A4A;
        letter-spacing: 5px !important;
        text-transform: capitalize !important;
    }

    .wemeetp {
        /* text-align: center !important; */
        font-size: 14px !important;
        line-height: 25px !important;
        padding: 10px !important
    ;
    }

   


    .hereitwork {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 65px;
        line-height: 75px;
        margin-top: 5%;
        /* letter-spacing: 0.3rem; */
        position: absolute;
        color: #5F5F5F;
        margin-left: 2%;
    }

    .servicesh1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        text-align: center;
        line-height: 144.5%;
        color: #5F5F5F;
    }

    /* .reservation2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 144.5%;
        color: #5F5F5F;
    } */
    .reservation2 {
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 26px !important;
        line-height: 17.16px !important;
        color: #4A4A4A !;
        padding: 10px !important;
        margin-top: 38%;
    }

    .servicelogt {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        margin-top: 13px;
    }

    .textcenter {
        text-align: left;
    }

    /* navbar issue */

    .ftco-navbar-light.scrolled .nav-item>.nav-link {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }

    .ftco-navbar-light .navbar-nav>.nav-item>.nav-link {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-top: 0 !important;
        color: #fff;
    }


    .reactangleoutside {
        position: absolute;
        /* width: 100px; */
        left: 0px;
        width: 100%;
        height: 132px;
        /* box-shadow: 0px 32px 60px rgb(0 0 0 / 12%); */
        box-shadow: 0px 216px 124px rgb(0 0 0 / 58%);
        /* top: 1080px; */
        top: 774px;
    }


    .bookbtn {
        /* style={{background: "#32A9E0", width: "200px", color: "#FFFFFF", fontWeight: "400px", borderRadius: "219px",  boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }}
         */
        background: #32A9E0;
        /* width: 127px; */
        width: 262px;
        color: "#FFFFFF";
        font-weight: 400;
        border-radius: 219px;
        /* box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.15); */


    }

    .css-ryrseu-MuiRating-root {
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        position: relative;
        font-size: 2rem !important;
        color: #faaf00;
        cursor: pointer;
        /* text-align: left; */
        -webkit-tap-highlight-color: transparent;
        /* text-align: center !important; */
        pointer-events: none;
        margin-left: 58px;
    }

    .copyright {
        font-size: 11px !important;
        font-weight: 400 !important;
        font-family: "Poppins" !important;
        letter-spacing: 0.1rem !important;
        line-height: 30px !important;
        color: #8C8C8C !important

    }
   



}

@media screen and (max-width: 375px) {

    .wemeetp {
        /* text-align: center !important; */
        font-size: 14px !important;
        line-height: 25px !important;
        padding: 10px !important

    }
    .addresscallmessage{
        font-family: "Poppins" !important;
        font-weight: 300 !important;
        font-size: 11px !important;
        line-height: 30.1px !important;
        letter-spacing: 0.1rem !important;
    }


    .btnlst{
        padding: 50px;
    }
    .rateprice {
        font-family: "Inter" !important;
        font-weight: 600 !important;
        font-size: 50px !important;
        line-height: 10px !important;
        padding: 35px !important;
    }

    .Rectangle1414 {
        /* left: 18px; */
        left: 2px;
        width: 338px;
        height: auto;
        position: relative !important;
        top: -67px;
        border: 2px solid black;
        text-align: center;
        padding-bottom: 14px;
    }

    .hereitwork {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 50px;
        line-height: 55px;
        margin-top: 5%;
        /* letter-spacing: 0.3rem; */
        position: absolute;
        color: #5F5F5F;
        margin-left: 2%;
    }

    .spanoftitle {
        margin-left: 0px;
    }


    .clearfix .fss {
        display: flex;
        flex-direction: row;
        padding: 0px 1px 0px 1px;
    }

    .mt-10 {
        margin-top: 0px !important;
    }

    .mt-5 {
        margin-top: 0px !important;
    }

    .servicelogt {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 35px;
        margin-top: 13px;
    }

    .reactangleoutside {
        position: absolute;
        /* width: 100px; */
        left: 0px;
        width: 100%;
        height: 132px;
        /* box-shadow: 0px 32px 60px rgb(0 0 0 / 12%); */
        box-shadow: 0px 216px 124px rgb(0 0 0 / 58%);
        /* top: 1049px; */
        top: 744px;
    }

    .css-ryrseu-MuiRating-root {
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        position: relative;
        font-size: 2rem !important;
        color: #faaf00;
        cursor: pointer;
        /* text-align: left; */
        -webkit-tap-highlight-color: transparent;
        /* text-align: center !important; */
        pointer-events: none;
        margin-left: 30px;
    }


}

@media screen and (max-width: 320px) {

    .wemeetp {
        /* text-align: center !important; */
        font-size: 14px !important;
        line-height: 25px !important;
        padding: 10px !important

    }
    /* .Rectangle1414 {
        left: 7px;
        width: 307px;
        height: 122px;
    } */

    .addresscallmessage{
        font-family: "Poppins" !important;
        font-weight: 300 !important;
        font-size: 11px !important;
        line-height: 30.1px !important;
        letter-spacing: 0.1rem !important;
    }
    

    .ratedescription {
        font-family: "Inter";
        font-weight: 400;
        font-size: 12px;
        line-height: 26.1px;
        letter-spacing: 0.1rem;
        text-transform: none;
        margin: 10px;

    
    }

    .servicename {
        font-family: "Sora" !important;
        font-weight: 600;
        font-size: 20px;
        line-height: 33.1px;
        letter-spacing: 0.1rem;
        text-transform: capitalize;
        margin: 10px;
    }
    .abcpr{
        margin-left: 26px;
    }

    .testimony-wrap.reservation{
        background: #FFFFFF;
        color: #000;
        height: 240px;
        border: 1px solid #979797;

    }
    .testypadd {
        padding: 0px 0px 0px 32px !important;
    }

    .wemeeth3 {
        /* text-align: center  !important; */
        font-family: "Poppins" !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 34.5px !important;
        color: #4A4A4A;
        letter-spacing: 5px !important;
        text-transform: capitalize !important;
    }

    
    .btnlst{
        padding: 4px;
    }

  

    /* .clearfix .fss {
        display: flex;
        flex-direction: row;
        padding: 0px 1px 0px 1px;
     } */
    .rateheading {
        font-family: "Poppins" !important;
        font-weight: 500 !important;
        font-size: 50px !important;
        line-height: 60.49px !important;
    }

    .rateprice {
        font-family: "Inter" !important;
        font-weight: 600 !important;
        font-size: 50px !important;
        line-height: 10px !important;
        padding: 35px !important;
    }

    .hereitwork {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        line-height: 40px;
        margin-top: 5%;
        /* letter-spacing: 0.3rem; */
        position: absolute;
        color: #5F5F5F;
        margin-left: 2%;
    }


    .lastbtn{
        width: 268px  !important;
        color: rgb(0, 0, 0) !important;
        border-radius: 219px;
        background: rgb(255, 255, 255) !important;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 50px !important;
        font-size: 24px !important;
        line-height: 24.96px !important;
        padding: 6px !important;
    }
    .Rectangle1414 {
        /* left: 19px; */
        left: 4px;
        width: 280px;
        height: auto;
        position: relative !important;
        top: -67px;
        border: 2px solid black;
        text-align: center;
        padding-bottom: 14px;
    }

    .ab {
        padding: 0 !important;
    }

    .bc {
        padding: 0 !important;
    }

    .cd {
        padding: 0 !important;
    }

    .spanoftitle {
        margin-left: 0px;
    }


    .clearfix.fss {
        display: flex;
        flex-direction: row;
        padding: 0px 1px 0px 1px;
    }

    .abc {
        margin-left: 52px !important;
    }

    .abcc {
        margin-left: 0px !important;
    }

    .mt-10 {
        margin-top: 0px !important;
    }

    .mt-5 {
        margin-top: 0px !important;
    }

    .wemeeth1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 40px;
        color: #5F5F5F;
        text-align: center;
        align-items: center;
        text-transform: capitalize;
    }

    .reactangleoutside {
        position: absolute;
        /* width: 100px; */
        left: 0px;
        width: 100%;
        height: 132px;
        /* box-shadow: 0px 32px 60px rgb(0 0 0 / 12%); */
        box-shadow: 0px 216px 124px rgb(0 0 0 / 58%);
        /* top: 1022px; */
        top: 703px;
    }


    .bookbtn {
        /* style={{background: "#32A9E0", width: "200px", color: "#FFFFFF", fontWeight: "400px", borderRadius: "219px",  boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }}
         */
        background: #32A9E0;
        /* width: 127px; */
        width: 212px;
        color: "#FFFFFF";
        font-weight: 400;
        border-radius: 219px;
        /* box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.15); */


    }

    .reservation2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 17.16px;
        color: #4A4A4A;
        margin-top: 30%;
    }

    .reservation22{
        font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 17.16px;
    letter-spacing: 0.3rem;
    /* or 38px */
    color: #5F5F5F;
    margin-top: 8%;
    }

    .servicelogt {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 66px;
        margin-top: 13px;
    }

    .css-ryrseu-MuiRating-root {
        display: inline-flex;
        position: relative;
        font-size: 2rem !important;
        color: #faaf00;
        cursor: pointer;
        /* text-align: left; */
        -webkit-tap-highlight-color: transparent;
        /* text-align: center !important; */
        pointer-events: none;
        margin-left: 30px;
    }

    .star5 {
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 35px !important;
        line-height: 80.65% !important;
color: #5F5F5F !important;

    }


    .welcomename {
        font-size: 19px;
    }

}

@media screen and (max-width: 375px) {

    .arrival {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 20px;
        line-height: 20.8px;
        color: #8E8E8E;
        padding: 0px 0px 0px 0px !important;
    }
    
    .wemeeth3 {
        /* text-align: center  !important; */
        font-family: "Poppins" !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 34.5px !important;
        color: #4A4A4A;
        letter-spacing: 5px !important;
        text-transform: capitalize !important;
    }

    .wemeeth1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 40px;
        color: #5F5F5F;
        text-align: center;
        align-items: center;
        text-transform: capitalize;
    }


    .ratedescription {
        font-family: "Inter";
        font-weight: 400;
        font-size: 12px;
        line-height: 26.1px;
        letter-spacing: 0.1rem;
        text-transform: none;
        margin: 10px;
    }
    .servicename {
        font-family: "Sora" !important;
        font-weight: 600;
        font-size: 20px;
        line-height: 33.1px;
        letter-spacing: 0.1rem;
        text-transform: capitalize;
        margin: 10px;
    }
    .reservation2 {
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 18px !important;
        line-height: 30px !important;
        color: #4A4A4A !important;
        margin-top: 30%;
       
    }
    .abcpr{
        margin-left: 20px;
    }


}



.px5 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;

}

.copyright {
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins";
    letter-spacing: 0.1rem;
    line-height: 30px;
    color: #8C8C8C

}


.addresscallmessage {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 17px;
    line-height: 30.1px;
    letter-spacing: 0.1rem;
}

.selects {
    border: none !important;
}

.selects:active {
    border: none !important;
}

.selects:hover {
    border: none !important;
}

.arrival {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 20px;
    line-height: 20.8px;
    color: #8E8E8E;
}

.lastbtn{

    width: 310px;
    color: rgb(0, 0, 0);
    border-radius: 219px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 50px;
    font-size: 24px;
    line-height: 24.96px;
}


.testypad {
    padding: 0px 0px 0px 60px ;

}


.ml{
    margin-left: 1rem !important;
}

