@import url('https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap');



/* body{
  background: #b696d7;
} */

.wrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  /* background: rgba(3, 156, 213); */
  padding: 35px 140px 15px 140px;
  border-radius: 5px;
}

/* .wrapper:before{
  content: "";
  position: absolute;
  top: 11px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 13px;
  background: white;
  border-radius: 50px;
} */

/* .wrapper:after{
  content: "";
  position: absolute;
  top: -181px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 200px;
  background: #353535;
  box-shadow: 0 5px 5px rgba(0,0,0,0.125);
} */

.idcard{
  width: 200px;
  height: 320px;
  background: #fff;
  padding: 20px 20px 0;
  position: relative;
  border-radius: 5px;
}

.idcard img{
  width: 100%;
  margin-bottom: 80px;
}

.idcard:before{
  content: "";
  position: absolute;
  top: 20px;
  left: 20px;
  width: 30%;
  height: 225px;
  /* background: #353535; */
  opacity: 0.8;
}

.idcard:after{
  /* content: "Coding Market";  */
  position: absolute;
  top: 125px;
  left: -50px;
  transform: rotate(-90deg);
  text-transform: uppercase;
  letter-spacing: 5px;
  color: #fff;
  font-weight: bold;
}

.idcard .info{
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 20px;
  background: #353535;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.idcard p{
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  font-weight: 300;
  color: #fff;
  text-align: center;
  font-size: 12px;
}




@media only screen and (max-width: 450px) {
  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-17%);
    padding: 35px 140px 15px 140px;
    border-radius: 5px;
  }
}




@media only screen and (max-width: 700px) {
  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-25%);
    padding: 35px 140px 15px 140px;
    border-radius: 5px;
  }
}