.swiper-slides {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 80px 11px;
  /* width: 405.667px !important;
  margin-right: 30px !important;
  margin-left: 48px !important; */

}



.servicename {
  text-transform: capitalize;
}

.servicelogt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 66%;
  margin-top: 13px;
}



/* .lineinput{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 4.8px solid #000;
} */

.lineinput {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #5F5F5F;
  width: 100%;
  margin-bottom: 10px;
}

.lineinput.abcc {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #5F5F5F;
  /* width: 552px; */
  width: 100%;
  height: 135%;
}

/* input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: blue
}
input:focus {
  border-color: green
} */


/* .letgettouch{
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 70px;
line-height: 107px;
} */

.letgettouch {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  line-height: 107.32px;
  color: #5F5F5F;
  text-transform: capitalize;
}

.servicelogtitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 33.32px;
  line-height: 41.31px;
  text-align: center;
  text-transform: capitalize;
}

.servicedescp{
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  line-height: 19.8px;
  text-align: center;
  text-transform: none;
}




@media screen and (max-width:768px) {

  .lineinput.abcc {
    outline: 0;
    border-width: 0 0 2px;
    border-color: #5F5F5F;
    /* width: 378px; */
    width: 100%;
    height: 118%;
  }

}

@media screen and (max-width: 320px){

.servicelogt{
  line-height: 35px !important;
}
}

@media screen and (max-width:375px) {

  .lineinput.abcc {
    outline: 0;
    border-width: 0 0 2px;
    border-color: #5F5F5F;
    /* width: 304px; */
    width: 100%;
    height: 118%;
  }

  .servicelogt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 36px ;
    margin-top: 13px;
    /* text-align: left; */
}

}

/* changes after api */
.mySwiper {
  height: 450px;
}


.swiper-button-prev {
  font-weight: bolder;
  color: #fff;
  left: 3px;
}

.swiper-button-next {
  font-weight: bolder;
  color: #fff;
  right: 3px;
}



.swiper-button-next {
  background: url('../valetuser/Vector1.png') center center no-repeat !important;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background: url('../valetuser/Vector-white.png') center center no-repeat !important;
}

.swiper-button-prev::after {
  display: none;
}


/* .mySwiperrating .swiper-button-next {
  font-weight: bolder;
  /* fill: #fff; */
/* color: #000; */
/* transform: matrix(0.71, -0.71, -0.71, -0.71, 0, 0); */
/* } */

/* .mySwiperrating .swiper-button-prev { */
/* font-weight: bolder; */
/* fill: #fff; */
/* color: #000; */
/* transform: matrix(0.71, -0.71, -0.71, -0.71, 0, 0); */
/* } */


span.swiper-pagination-bullet {
  background: #FFFFFF;
  opacity: 1;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {

  background: #32A9E0;
}

/* .swiper-pagination.swiper-pagination-bullet {
  opacity: 1;
  border: white solid 1px;
  background: #32A9E0;
}
.swiper-pagination.swiper-pagination-bullet-active {
  background: #32A9E0;
} */


@media screen and (max-width:425px) {

  .servicelogtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22.975px;
    line-height: 124%;
    text-align: left;
}

.servicelogt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px ;
  margin-top: 13px;
  /* text-align: left; */
}
  
}