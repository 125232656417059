



/* .swiper {
  width: 100%;
  height: 100%;
} */



/* .swiper-slide img {
  width: auto;
  height: auto; 
  max-width: 100%; 
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
   top: 50%;
} */


.swiper-slidess {

  padding: 46px 130px;
}


@media screen and  (max-width: 1000px)and (min-width:700px)  {
  #clock {
    position: relative !important;
    z-index: 1 !important;
    font-size: 25px !important;
    left: 58% !important;
    top: 56% !important;
  }
}


@media screen and  (max-width: 320px)  {
  #clock {
    position: relative !important;
    z-index: 1 !important;
    font-size: 25px !important;
    left: 62% !important;
    top: 55% !important;
  }
}
.nav-tabs .nav-link{
  color: rgba(3, 156, 213) !important;
}

.nav-tabs .nav-link:hover{
  color: white !important;
  background-color: rgba(3, 156, 213) !important;
  border-radius: 36px !important;

}


.nav-pills .nav-link{
  color: rgba(3, 156, 213) !important;
  border-radius: 36px !important;
  border: 1px solid rgba(3, 156, 213) !important;
  margin: 5px !important;

}


.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: white !important;
  background-color:  #32A9E0 !important;
  ;
}


@media screen and  (max-width: 1000px)and (min-width:700px)  {
  #clock1 {
    position: relative !important;
    z-index: 1 !important;
    font-size: 25px !important;
    left: 52% !important;
    top: 56% !important;
  }
}


@media screen and  (max-width: 320px)  {
  #clock1 {
    position: relative !important;
    z-index: 1 !important;
    font-size: 25px !important;
    left: 58% !important;
    top: 54% !important;
  }
}


.capi{
  text-transform: capitalize;
}

.nav-link{
  text-transform: capitalize;
}