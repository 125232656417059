.react-calendar { 
    width: 800px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
   }
   .react-calendar__navigation button {
    color: #6f48eb;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
   }
   .react-calendar__navigation button:enabled:hover,
   .react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
   }
   .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
   }
   abbr[title] {
    text-decoration: none;
   }
   /* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */
   .react-calendar__tile:enabled:hover,
   .react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    /* color: #6f48eb; */
    border-radius: 6px;
   }
   .react-calendar__tile--now {
    background: #6f48eb33;
    border-radius: 6px;
    font-weight: bold;
    /* color: #6f48eb; */
   }
   .react-calendar__tile--now:enabled:hover,
   .react-calendar__tile--now:enabled:focus {
    background: #6f48eb33;
    border-radius: 6px;
    font-weight: bold;
    /* color: #6f48eb; */
   }
   .react-calendar__tile--hasActive:enabled:hover,
   .react-calendar__tile--hasActive:enabled:focus {
    background: #6f48eb;
    color: #fff;
   }
   .react-calendar__tile--active {
    /* background: #6f48eb; */
    border-radius: 6px;
    font-weight: bold;
    color: white;
   }
   .react-calendar__tile--active:enabled:hover,
   .react-calendar__tile--active:enabled:focus {
    background: #6f48eb;
    color: #fff;
   }
   .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
   }
   .react-calendar__tile--range {
    background: #f8f8fa;
    color: #66656b;
    border-radius: 0;
   }
   .react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #6f48eb;
    color: white;
   }
   .react-calendar__tile--rangeEnd {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: #6f48eb;
    color: white;
   }


   /* .input-group {
    color: #333;
    float: left;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 20px;
    margin: 0 20px 10px;
    width: 200px;
  } */
  
  /* label {
    display: block;
    margin-bottom: 2px;
  }
   */

  
  .error-message {
    color: #cc0033;
    display: inline-block;
    font-size: 12px;
    line-height: 15px;
    margin: 5px 0 0;
  }
  
  /* .input-group .error-message {
    display: none;
  } */
  
  
  /* Error Styling */
  
  .error label {
    color: #cc0033;
  }
  
  .error input[type=text] {
    background-color: #fce4e4;
    border: 1px solid #cc0033;
    outline: none;
  }
  
  .error .error-message {
    display: inline-block;
  }
  
  
   