


@media only screen and (max-width: 768px) {
    /* For mobile phones: */
     
    .ftco-navbar-light.scrolled .navbar-toggler {
        border: none;
        color: rgba(0, 0, 0, 0.5) !important;
        /* border-color: rgba(0, 0, 0, 0.5) !important; */
        cursor: pointer;
        padding-right: 0;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: .1em;
        margin-top: 24px;
    }
  
  }