.bg-dark1 {
    /* --bs-bg-opacity: 1; */
    background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important;
}


.bg-dark2 {
    --bs-bg-opacity: 1;
    /* background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important; */
    background: rgba(3, 156, 213);
    color:#000
}

/* .navbar-dark .navbar-nav .nav-link {
    color: cyan !important;
    font-weight: bold;

} */

.navbar-dark .navbar-nav .nav-link {
    color: white;
    font-weight: bold;
}

.navbar-dark .navbar-nav :hover > .nav-link {
    color: #32A9E0 !important;
}

.mx-4{
    margin-right: 2.5rem!important;
    margin-left: 2.5rem!important
}


/* .navbar-dark .navbar-nav .nav-link.active {
    color: red !important;
    font-weight: bold;
} */

/* .navbar-dark.navbar-nav > .nav-item.active > a {
    color: #fff;
  } */


/* .navbar-dark .navbar-nav > .nav-item.active > a {
    color: #000000;
} */

/* .navbar-dark .navbar-nav .nav-link:active {
    color: darkgray;
    font-weight: bold !important;
} */

/* li.nav-item.active.mx-3 {
    color: #000;
    font-weight: bold !important;
}

.nav-item .active.mx-3
{
  color: #000!important;
} */

.navbar-dark .navbar-brand {
    color: #fff;
    text-align: center;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: white;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}



@media screen and (max-width: 768px) {

    .bg-dark1 {
        --bs-bg-opacity: 1;
        background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important;
    }

}

@media screen and (max-width: 768px) {

    .sliderlogo {
        width: 50% !important;
        /* margin-top: 60px; */

    }

}


@media screen and (max-width: 425px) {

    .sliderlogo {
        width: 30% !important;
        /* margin-top: 10px; */
    }

    .widthclass{
        margin-top: 0px !important;
    }

}

.saf {
    color: rgb(129 221 255) !important;
}

 /* .navbar-nav .nav-link{
    color: #007aff !important;

 } */

 .logowidth{
    margin-top: -65px !important;
 }


@media screen and (max-width: 425px) {

    .navbar-dark .navbar-brand {
        color: #fff;
        width: 220px;
    }
}

@media screen and (max-width: 425px) {

    .navbar-dark .navbar-brand {
        color: #fff;
        width: 212px;
    }
}



/* CSS used here will be applied after bootstrap.css */

.dropdown {
    display:inline-block;
    margin-left:20px;
    padding:10px;
  }


.glyphicon-bell {
   
    font-size:1.5rem;
  }

.notifications {
   min-width:420px; 
   background-color: #32A9E0;
   height: 240px;
   border-radius: 0px 0px 0px 42px !important;
   opacity: 0.9;
  }
  
  .notifications-wrapper {
     overflow:auto;
      max-height:250px;
    }

    .lis{
        font-family: "Poppins";
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
       
    }
    
 .menu-title {
     color:#ff7788;
     font-size:1.5rem;
      display:inline-block;
      }
 
.glyphicon-circle-arrow-right {
      margin-left:10px;     
   }
  
   
 .notification-heading, .notification-footer  {
 	padding:2px 10px;
       }
      
        
.dropdown-menu.divider {
  margin:5px 0;          
  }

.item-title {
  
 font-size:1.3rem;
 color:#000;
    
}

.notifications a.content {
 text-decoration:none;
 background:#ccc;

 }
    
.notification-item {
 padding:10px;
 margin:5px;
 background:#ccc;
 border-radius:4px;
 }



