.swiper3 {
  width: 100%;
  height: 400px;


}



/* Center slide text vertically */

.swiper-slides3 {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */


  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slides3 img {
  /* display: block; */
  /* width: 100%; */
  /* height: 100%; */
  /* object-fit: cover; */


  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

/* 
  .swiper-slide.swiper-slides.swiper-slide-active.testimony-wrap {
    display: block;
    position: relative;
    background: #f5f5f5;
    padding: 0 20px;
    margin-bottom: 3rem!important;
    color: black;
    text-align: center!important;
    padding: 1rem!important;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    margin-bottom: 3rem!important;
    border-radius: 0.25rem!important;
} 

.swiper-slide.swiper-slides.swiper-slide-prev.testimony-wrap {
  display: block;
  position: relative;
  
  padding: 0 20px;
  margin-bottom: 3rem!important;
  background: #000;
  color: black;
  text-align: center!important;
  padding: 1rem!important;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  margin-bottom: 3rem!important;
  border-radius: 0.25rem!important;
}  */

/* .swiper-button-prev.ab {
  left: 63% !important;
  top: 40% !important;
  transform: rotate(90deg);
  transform-origin: left center;
  background: url('../valetuser/Vector-black.png') center center no-repeat!important;
}
.swiper-button-next.ab {
  left: 63% !important;
  bottom: 49% !important;
  transform: rotate(90deg);
  transform-origin: left center;
  background: url('../valetuser/Vector-black.png') center center no-repeat!important;
} */

/* .swiper-prev {
  position: absolute;
  width: 44.77px;
  height: 44.77px;
  padding-top: 7px;
  left: 98%;
  top: 58%;
  text-align: center;
  background: #D9D9D9;
  border-radius: 50%;
} */

.swiper-prev {
  position: absolute;
  width: 44.77px;
  height: 44.77px;
  padding-top: 7px;
  left: 98%;
  top: 58%;
  text-align: center;
  background: #D9D9D9;
  border-radius: 50%;
}

.swiper-prev:hover {
  position: absolute;
  width: 44.77px;
  height: 44.77px;
  padding-top: 7px;
  left: 98%;
  top: 58%;
  text-align: center;
  background: #32A9E0;
  border-radius: 50%;
  color: #FFFFFF;
  cursor: pointer;
}

.swiper-next {
  position: absolute;
  width: 44.77px;
  height: 44.77px;
  padding-top: 7px;
  left: 98%;
  top: 76%;
  text-align: center;
  background: #D9D9D9;
  border-radius: 50%;
}

.swiper-next:hover {
  position: absolute;
  width: 44.77px;
  height: 44.77px;
  padding-top: 7px;
  left: 98%;
  top: 76%;
  text-align: center;
  background: #32A9E0;
  border-radius: 50%;
  color: #FFFFFF;
  cursor: pointer;
}


.bgdesign {
  background: #FFFFFF;
  border-radius: 13px;
}

.slider-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 110%;
}

.slider-text2 {
 font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 110%;
    position: absolute;
    left: 140px;
    top: 45px;
}

@media screen and (max-width:425px) {

  .swiper-prev {
    position: relative;
    width: 44.77px;
    height: 44.77px;
    padding-top: 7px;
    left: -10%;
    top: 58%;
    text-align: center;
    background: #D9D9D9;
    border-radius: 50%;
  }
  
  .swiper-next {
    position: relative;
    width: 44.77px;
    height: 44.77px;
    padding-top: 7px;
    left: 10%;
    top: 76%;
    text-align: center;
    background: #D9D9D9;
    border-radius: 50%;
  }

  .swiper-prev:hover {
    position: relative;
    width: 44.77px;
    height: 44.77px;
    padding-top: 7px;
    left: -10%;
    top: 58%;
    text-align: center;
    background: #32A9E0;
  border-radius: 50%;
  color: #FFFFFF;
  cursor: pointer;
  }
  
  .swiper-next:hover {
    position: relative;
    width: 44.77px;
    height: 44.77px;
    padding-top: 7px;
    left: 10%;
    top: 76%;
    text-align: center;
    background: #32A9E0;
    border-radius: 50%;
    color: #FFFFFF;
    cursor: pointer;
  }

  .parent-btn{
 
    justify-content: center;
    margin-bottom: 7%;

  }
  .swiper3 {
    width: 100%;
    height: 360px;
}
  
}
