
  .popup-box {
    width: 400px;
    background-color: #eee;
    padding: 30px;
    font-size: 36px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    display: none;
  }
  .popup-box span {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    background-color: red;
    color: white;
    padding: 5px;
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  /* p {
    font-size: 20px;
    color: #777;
  }
   */