



.content {
  
  display: flex;
  justify-content: center;
  padding: 2rem;
  /* width: 400px; */
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: grey;
  transition: 0.4s;
}

.toggle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .toggle {
  background-color: blue;
}

input:focus + .toggle {
  box-shadow: 0 0 0 4px rgba(21, 156, 228, 0.7);
  outline: none;
}

input:checked + .toggle:before {
  transform: translateX(26px);
}

.toggle.round {
  border-radius: 34px;
}

.toggle.round:before {
  border-radius: 50%;
}
