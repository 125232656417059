/* ahmed add */
body, html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Not supported in IE 6 & IE 7 */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 770px) {
  .abcd {
    height: 135%;

  }
}


/* .modal-title{

  margin-bottom: 0;
  line-height: 1.5;
  margin-left: 181px;
  font-size: 38px;

} */

.modal-title {
  margin-bottom: 0;
  font-size: 43px !important;
  font-family: serif;
  line-height: 1.5;
  margin-left: 170px;
}

.bgmycss{
  
  color: black;
background: white;
}

.bgmycss:hover{
  
  color: white;
background: #32A9E0;
transition: 0.5s ease;
}


.swiper-slide img {
  display: block;
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}




.slick-prev, .slick-next {
  background-color: black !important;
  right: 0px !important;

}



/* .slick-next {
  
  background: url('../public/assets/images/about.jpg');
  
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.slick-next::after {
  display: none;
}
  */





.item-reservation{
  /* width: 360px; */
  width: 90%;
}



