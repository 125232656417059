.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  padding: 0px 4px 0px 29px;
}

.slick-next {
  background: url('../valetuser/Vector1.png') center center no-repeat!important;

  
  /* &::before {
    display: none;
  } */

  /* right: 4px!important; */
  height: 41px !important;

}

/* .slick-next {
  right: 4px!important;
  height: 41px;
} */

.rounded{
  border-radius: 18.85px !important;
}

.slick-next::before {
  display: none;
}



.slick-prev {
  background: url('../valetuser/Vector-black.png') center center no-repeat!important;

  /* left: -23px!important; */
  left: -12px!important;
  height: 41px !important;

  /* &::before {
    display: none;
  } */
}

.slick-prev::before {
  display: none;
}

/* .slick-active {
  background: red;
} */



/* .slick-active + .slick-active {
  background: blue;
}

.slick-active + .slick-active + .slick-active {
  background: yellow;
} */


.swiper-slidesrating {
    font-size: 18px;
    color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 110px 35px; 
  }

  /* .swiper-slide.swiper-slidesrating.swiper-slide-active {
    margin-right: 0px;
    margin-left: 3px;
    padding-right: 0px;
} */
/* .swiper-slide.swiper-slidesrating {
  margin-right: 0px;
    margin-left: -16px;
    padding-right: 0px;
}


.item.reservation{
  margin-left: 36px;
    width: 346px;
}  */

  /* .mySwiperrating {
    height: 450px !important;
  } */

  .servicename{
    text-transform: capitalize;
  }

  

  .servicelogt{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 17.16px;
    margin-top: 13px;
    letter-spacing: 0.5rem;
  }

  .servicelogtitle2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20.7px;
    margin-top: 13px;
   


  }
  
  /* .lineinput{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 4.8px solid #000;
} */

.letgettouch{
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 70px;
line-height: 107px;
}

/* changes after api */
/* .mySwiper {
  height: 450px;
}

.swiper-slidesrating-button-prev {
  font-weight: bolder;
  /* color: #000; */
  /* color: red; */
/* } */


.mySwiperrating .swiper-button-next {
  font-weight: bolder;
  color: #000 !important;
  
}

.mySwiperrating .swiper-button-prev {
  font-weight: bolder;
  
  color: #000 !important;
  
}
/* .item.reservation {
  width: 350px;
} */




/* .swiper-button-next,
.swiper-button-prev {
    background-color: white;
    background-color: rgba(255, 255, 255, 0.5);
    right:10px;
    padding: 30px;
    color: #000 !important;
    fill: black !important;
    stroke: black !important;
} */

/* .swiper-button-prev{
  position: absolute;
  width: 32px;
  height: 32px;
  left: 116.63px;
  top: 3377.25px;
  
  background: #000000;
  transform: rotate(-135deg);
}

.swiper-button-next{
  position: absolute;
width: 32px;
height: 32px;
left: 1322.63px;
top: 3377.25px;

background: #32A9E0;
transform: matrix(0.71, -0.71, -0.71, -0.71, 0, 0);
} */


/* .mySwiperrating .swiper-button-next,
.swiper-button-prev {
    background-color: white;
    background-color: rgba(255, 255, 255, 0.5);
    right:10px;
    padding: 30px;
    color: #000 !important;
    fill: black !important;
    stroke: black !important;
} */


.reservation22{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 26px;
line-height: 17.16px;
letter-spacing: 0.3rem;
/* or 38px */
color: #5F5F5F;
margin-top: 8%;
}

.reservation-span{
  /* color: white; */
    font-size: 20px;
    position: absolute;
    top: 14%;
    left: 26%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    line-height: 20.7px;
    text-transform: capitalize;
}

.reservation-span:hover{
  /* color: #000; */
    font-size: 20px;
    position: absolute;
    top: 14%;
    left: 26%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    line-height: 20.7px;
}



.mySwiperrating-slidesrating .slidesrating-active.item.testimony-wrap.rounded.text-center.py-4.shadow.p-3.mb-5.bgmycss.rounded:active {
  background: red;
}

.testimony-wrap.reservation{
  background: #FFFFFF;
  color: #000;
  height: 212px;
  border: 1px solid #979797;
}

.testimony-wrap.rating{
  background: #32A9E0;
  color: white;
  border-radius: 18.85px !important;
  /* height: 242px; */

}

.testimony-wrap.reservation:hover{
  background: #32A9E0;
  color:#FFFFFF;
  height: 212px;
  transition: 0.5s ease;
  

  
}

.testimony-wrap.rating:hover{
  background: #FFFFFF;
  color:#000;
  /* height: 242px; */
  border-radius: 18.85px !important;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  
}

.reservationp{
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22.5px;
}

.reservationpr{
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17.6px;
    color: #B6B6B6;
}


.reviewheading{
  font-family: "Poppins";
font-weight: 600;
font-size: 20px;
line-height: 22px;
color: #1F1A1A;
/* letter-spacing: 0.3rem; */
text-transform: capitalize;

}
.reviewdescription{
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #5F5F5F;
  /* letter-spacing: 0.3rem; */
  text-transform: capitalize;

}





@media screen and (max-width:320px) {

  .abc {
    margin-left: 47px !important;
    font-size: 12px !important;
  
 
  }

}


@media screen and (max-width:375px) {

  .abc {
    margin-left: 23px !important;
 
  }

}



@media screen and (max-width:425px) {

  .abc {
    margin-left: 30px !important;
 
  }

  .icon-left{
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
 
  }
  .abb{
    margin-top: 0px !important;
  }

  .icon-right{
    text-align: center !important;
    display: none !important;
  }
  .showicon{
    display: block !important;
  }


  
.letgettouch{
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 45px;
line-height: 55px;
}

}


.testimony-wrap.abc:hover{
  color:#000;
}

.testimony-wrap.white-wala-text:hover{
  color:#000;
}
/* .white-wala-text{
color: #FFFFFF;
}
.white-wala-text:hover{
  color:#000;
  }  */