
:root {
    --primary-color: #010712;
    --secondary-color: #818386;
    --bg-color: #FCFDFD;
    --button-color: #3B3636;
    --h1-color: #3F444C;
  }
  .contact-container {
    display: flex;
    /* width: 100vw; */
    height: 100vh;
    background: var(--bg-color);
}

.left-col {
    width: 45vw;
    height: 100%;
    background-image: url("https://images.pexels.com/photos/931018/pexels-photo-931018.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500");
    background-size: cover;
    background-repeat: no-repeat;
}

.contactlogo {
    width: 10rem;
    padding: 1.5rem;
}

.right-col {
    background: var(--bg-color);
    width: 50vw;
    height: 100vh;
    padding: 5rem 3.5rem;
}

.contact-h1,
.contact-label,
.contact-button,
.contact-description {
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    letter-spacing: 0.1rem;
}

.contact-h1 {
    color: var(--h1-color);
    text-transform: uppercase;
    font-size: 2.5rem;
    letter-spacing: 0.5rem;
    font-weight: 300;
}

.contact-p {
    color: var(--secondary-color);
    font-size: 0.9rem;
    letter-spacing: 0.01rem;
    width: 40vw;
    margin: 0.25rem 0;
}

.contact-label,
.description {
    color: var(--secondary-color);
    text-transform: uppercase;
    font-size: 0.625rem;
}

.contact-form {
    /* width: 31.25rem; */
    position: relative;
    /* margin-top: 2rem; */
    padding: 1rem 0;
}

.contact-input,
.contact-textarea,
.contact-label {
    width: 40vw;
    display: block;
}

.contact-p,
placeholder,
.contact-input,
.contact-textarea {
    font-family: 'Helvetica Neue', sans-serif;
}

.contact-input::placeholder,
.contact-textarea::placeholder {
    color: var(--primary-color);
}

.contact-input,
.contact-textarea {
    color: var(--primary-color);
    font-weight: 500;
    background: var(--bg-color);
    border: none;
    border-bottom: 1px solid var(--secondary-color);
    padding: 0.5rem 0;
    margin-bottom: 1rem;
    outline: none;
}

.contact-textarea {
    /* resize: none; */
}

.contact-button {
    text-transform: uppercase;
    font-weight: 300;
    background: var(--button-color);
    color: var(--bg-color);
    width: 10rem;
    height: 2.25rem;
    border: none;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
}

.contact-input:hover,
.contact-textarea:hover,
.contact-button:hover {
    opacity: 0.5;
}

.contact-button:active {
    opacity: 0.8;
}

