/* video {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    }
     
    #play-pause{
        cursor: pointer;
    text-indent: -999999px;
    height: 40px;
    width: 40px;
    top: 46%;
    left: 39%;
    position: absolute;
    padding: 39px 42px;
    z-index: 2;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMiAyNHYtMjRsMjAgMTItMjAgMTJ6Ii8+PC9zdmc+);
    background-repeat: no-repeat!important;
    background-position: center;
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    background-color: gainsboro;
    border-radius: 42px;
      }
    
      .play {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMiAyNHYtMjRsMjAgMTItMjAgMTJ6Ii8+PC9zdmc+);
      }
      .pause {
         background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTAgMjRoLTZ2LTI0aDZ2MjR6bTEwLTI0aC02djI0aDZ2LTI0eiIvPjwvc3ZnPg==)!important;
      }
 */



      .video-react.video-react-fluid, .video-react.video-react-16-9, .video-react.video-react-4-3 {
        width: 100%;
        max-width: 100%;
        height: 100%;
    }


    @media screen and (max-width: 425px) {
      
      /* .videocolumns{
        margin-top: 25.5% !important;
      } */

      /* .videocolumns {
        margin-top: 14.5% !important;
    } */
    
       .videocolumns {
        margin-top: 13.5% !important;
    } 
    

    .container-fluid {
        width: 100%;
        /* padding-right: var(--bs-gutter-x,.75rem); */
        /* padding-left: var(--bs-gutter-x,.75rem); */
        margin-right: auto;
        margin-left: auto;
    }
    .container-fluid {
      width: 100%;
      padding-right: 0px !important;
      padding-left: 0px !important;
      margin-right: auto;
      margin-left: auto;
  }

  .airportimg{
    display: none !important;
  }

  .airportimg1{
    display: none !important;
  }

    }
    
    @media screen and (max-width: 375px) {
      
      /* .videocolumns{
        margin-top: 26.5% !important;
      } */
      .videocolumns {
        margin-top: 15.5% !important;
    }

    }
    @media screen and (max-width: 768px) {
      .parallax-bg{
        height: 100vh !important;
      }

      .videocolumns {
        margin-top: 17.5% !important;

    }

    .swiper-slide .title{
      font-family: "Poppins" !important;
      font-weight: 500 !important;
      font-size: 44px !important;
      line-height: 60px !important;
      margin-top: 15% !important;
      text-transform: capitalize !important;
      margin-left:10% !important;
    }

    .swiper-slide .subtitle{
      font-family: "Poppins" !important;
      font-weight: 400 !important;
      font-size: 15px !important;
      line-height: 18.88px !important;
      margin-left:10% !important;
       /* color: #8E8E8E; */
      text-transform: capitalize !important;
      margin-top: 1% !important;
 }

 .swiper-slide .information{

  font-family: "Poppins" !important;
   font-weight: 500 !important;
   font-size: 40px !important;
   line-height: 62px !important;
   margin-top: 26% !important;
   text-transform: capitalize !important;
  text-align: center !important;
}

    }


    @media screen and (max-width: 1024px) {
      .parallax-bg{
        height: 100vh !important;
      }

      .videocolumns {
        margin-top: 17.5% !important;


    }



    .swiper-slide .title{
      font-family: "Poppins" !important;
      font-weight: 500 !important;
      font-size: 40px !important;
      line-height: 50px !important;
      margin-top: 10% !important;
      text-transform: capitalize !important;
      margin-left:10% !important;
    }


    .swiper-slide .information{

      font-family: "Poppins" !important;
       font-weight: 500 !important;
       font-size: 45px !important;
       line-height: 62px !important;
       margin-top: 20% !important;
       text-transform: capitalize !important;
      text-align: center !important;
  }

    }
     
    @media screen and (max-width: 425px) {
      .parallax-bg{
        height: 100vh !important;
      }

      .videocolumns {
        margin-top: 17.5% !important;



    }


    .swiper-slide .title{
      font-family: "Poppins" !important;
      font-weight: 500 !important;
      font-size: 30px !important;
      line-height: 35px !important;
      margin-top: 28% !important;
      text-transform: capitalize !important;
      margin-left:0% !important;
    }

    .swiper-slide .subtitle{
      font-family: "Poppins" !important;
      font-weight: 400 !important;
      font-size: 13px !important;
      line-height: 16.88px !important;
      margin-left:0% !important;
       /* color: #8E8E8E; */
      text-transform: capitalize !important;
      margin-top: 1% !important;
 }

    .swiper-slide .information{

      font-family: "Poppins" !important;
       font-weight: 500 !important;
       font-size: 40px !important;
       line-height: 62px !important;
       margin-top: 45% !important;
       text-transform: capitalize !important;
      text-align: center !important;
    }
    

    }


    @media screen and (max-width: 320px) {
           .parallax-bg{
            height: 100vh !important;
      
          }  
           
      
            .videocolumns {
              margin-top: 17.5% !important;
      
      
      
          }

          
          .swiper-slide .title{
            font-family: "Poppins" !important;
            font-weight: 500 !important;
            font-size: 28px !important;
            line-height: 35px !important;
            margin-top: 35% !important;
            text-transform: capitalize !important;
            margin-left:0% !important;
          }

          .swiper-slide .information{

            font-family: "Poppins" !important;
             font-weight: 500 !important;
             font-size: 35px !important;
             line-height: 62px !important;
             margin-top: 55% !important;
             text-transform: capitalize !important;
            text-align: center !important;
          }

          .swiper-slide .subtitle{
            font-family: "Poppins" !important;
            font-weight: 400 !important;
            font-size: 13px !important;
            line-height: 16.88px !important;
            margin-left:0% !important;
             /* color: #8E8E8E; */
            text-transform: capitalize !important;
            margin-top: 1% !important;
       }
      
          }





    .swiper-slide .title{
      font-family: "Poppins";
      font-weight: 500;
      font-size: 42px;
      line-height: 42px;
      margin-top: 7%;
      text-transform: capitalize;
      margin-left:10% ;
    }


    .swiper-slide .subtitle{
      font-family: "Poppins";
      font-weight: 400;
      font-size: 15px;
      line-height: 18.88px;
      margin-left:10% ;
       /* color: #8E8E8E; */
      text-transform: capitalize;
      margin-top: 1%;
 }


 .swiper-slide .information{

     font-family: "Poppins";
      font-weight: 500;
      font-size: 45px;
      line-height: 62px;
      margin-top: 16%;
      text-transform: capitalize;
     text-align: center ;
 }

    /* .parallax-bg{
      height: 100vh ;
    } */