/*From Right/Left */
.modal.drawer {
    display: flex !important;
    pointer-events: none;
}
.modal.drawer * {
    pointer-events: none;
}
.modal.drawer .modal-dialog {
    margin: 0px;
    display: flex;
    flex: auto;
    transform: translate(25%, 0);
}
.modal.drawer .modal-dialog .modal-content {
    border: none;
    border-radius: 0px;
}
.modal.drawer .modal-dialog .modal-content .modal-body {
    overflow: hidden;
}
.modal.drawer.show {
    pointer-events: auto;
}
.modal.drawer.show * {
    pointer-events: auto;
}
.modal.drawer.show .modal-dialog {
    transform: translate(0, 0);
}
.modal.drawer.right-align {
    flex-direction: row-reverse;
}
.modal.drawer.left-align:not(.show) .modal-dialog {
    transform: translate(-25%, 0);
}


.drawer.drawer-closed {
/* 	width: 370px;
	height: 100vh;
	background-color: #fff; */
	margin-left: -50%;
/* 	float: left;
	box-shadow: 0px 0px 5px #7f7f7f; */
}

.drawer {
	/* width: 50%; */
	min-height: 50% !important;
	height: 60% !important;
	/* background-color: #fff; */
	/* margin-left: 0px; */
	/* float: left; */
	/* box-shadow: 0px 0px 5px #7f7f7f; */
}

.lis {
	font-family: "Poppins";
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	list-style: none;
	padding: 0em 0em 1em 2em;
	float: left;
	clear: left;
    color: white;
}

/* ul li:first-child {
	padding-top: 1em;
} */

#drawerOpener {
	float: left;
	margin-top: 10px;
	margin-left: 18px;
}
/* .modal-content{
	background-color: #32A9E0;
	border-radius: 8px 50px !important;
} */


a.lis:hover {
    color: #ffff;
}

.modalcss{
	height: 100%;
}

@media screen and (max-width: 768px) {
	.modalcss{
		height: 97%;
		padding-left: 2px;
	}

}

@media screen and (max-width: 425px) {
	.modalcss{
		height: 97%;
		padding-left: 10px;
	}

}