
.serviceBox{
  
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 0 30px 30px;
    position: relative;
    /* z-index: 1; */
    min-height: 100%;
}

@media only screen and (max-width: 1199px){

    .serviceBox1 {
        
        margin-top: 2%;
        padding: 0px 90px;
    }}


 @media only screen and (max-width: 1440px){

        .serviceBox1 {
            
            margin-top: 2%;
            padding: 0px 90px;
        }}
    

@media only screen and (max-width: 425px){

            .serviceBox1 {
                
                margin-top: 2%;
                padding: 0px 24px;
            }
            .reservation2 {
                    
                line-height: 32px;
             }}
        
        


              
.serviceBox:before,
.serviceBox:after{
    content: "";
    background: #fff;
    border-radius: 20px;
    position: absolute;
    top: 38px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    z-index: -1;
}
.serviceBox:after{
    background-color: var(--main-color);
    border-radius: 30px;
    top: 10%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    border: 1px solid black
}
.serviceBox .service-icon{
    color: black;
    background: white;
    font-size: 12px;
    line-height: 80px;
    width: 75px;
    height: 75px;
    margin: 0 auto 40px;
    border-radius: 50%;
    border: 5px solid #fff;
    position: relative;
    border: 1px solid black;
}
.serviceBox .service-icon:before,
.serviceBox .service-icon:after{
    content: '';
    background-color: var(--main-color);
    height: 70px;
    /* width: 140px; */
    border-radius: 0 0 100px 100px/0 0 100px 100px;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
}
.serviceBox .service-icon:before{
    height: 10px;
    /* width: 170px; */
    border-radius: 10px 10px 0 0;
    top: auto;
    bottom: 50%;
}
.serviceBox .title{
    font-family: "Poppins";
    line-height: 29.96px;
    font-size: 28px;
    font-weight: 600;
    text-transform: capitalize;
   color: #1E1E1E;
    margin: 0 0 10px;
}



.serviceBox .description{
    font-family: "Poppins";
    color: #5F5F5F;
    font-size: 18px;
    line-height: 27px;
    margin: 0;
    font-weight: 400;
    text-transform: none;

}

.steps{

    font-family: "Poppins";
    color: #4A4A4A;
    font-size: 15.28px;
    line-height: 10.09px;
    font-weight: 600;
   
}
.serviceBox:hover .title {
/* background-color: #32A9E0; */
color: #fff;
}

.serviceBox:hover .description {
    /* background-color: #32A9E0; */
    color: #fff;
    }

    .serviceBox:hover::before{
    background-color: #32A9E0;
    border-radius: 20px;
    position: absolute;
    top: 38px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /* z-index: -2; */
    transition: 0.5s ease;
    
    

    }
    .serviceBox:hover::after{
        /* background-color: #32A9E0; */
        border:none;
        /* transition: 0.5s ease-in; */
        
        
    
        }


    .service-icon:hover{
       border: 2px solid #32A9E0 !important;
        }
.serviceBox.orange{ --main-color: #e77a1b; }
.serviceBox.blue{ --main-color: #0C6CBA; }
.serviceBox.green{ --main-color: #4CA131; }
@media only screen and (max-width: 768px){
    .serviceBox{ margin: 0 0 0; }
}

/* @media only screen and (max-width: 1199px){
    .serviceBox{
      margin:0 0 30px  
         }


        }  */

@media only screen and (max-width: 320px){
    .serviceBox .title{
        font-family: "Poppins";
        line-height: 29.96px;
        font-size: 17px;
        font-weight: 500;
        text-transform: capitalize;
        color: #1E1E1E;
        margin: 0 0 10px;
         }


         .serviceBox .description{
            font-family: "Poppins";
            line-height: 27px;
            font-size: 14px;
            font-weight: 400;
            text-transform: none;
            color: #5F5F5F;
            margin: 0 
             }

           

}


@media only screen and (max-width: 1024px){
    .serviceBox .title{
        font-family: "Poppins";
        line-height: 16.96px;
        font-size: 11px;
        font-weight: 600;
        text-transform: capitalize;
        color: #1E1E1E;
        margin: 0 0 10px;
         }


         .serviceBox .description{
            font-family: "Poppins";
            line-height: 17px;
            font-size: 13px;
            font-weight: 400;
            text-transform: none;
            color: #5F5F5F;
            margin: 0 
             }
}
@media only screen and (max-width: 425px) and (min-width: 320px)  {
    .serviceBox{
        color: var(--main-color);
        font-family: "Poppins";
        font-weight: 400;
        text-align: center;
        padding: 0px 7px 0px;
        position: relative;
        /* z-index: 1; */
        min-height: 100%;
        margin: 0 0 20px;
    }

    
    
    }


 

